import { Link } from "gatsby"
import React from "react"
import ThemeToggle from "./themeToggle"
import { NavLink, BlobHeader } from "./atoms"

const Header = ({ activePage }) => (
  <header className="container m-auto px-5 sm:px-12 md:px-20 max-w-screen-xl h-32 overflow-hidden">
    <nav
      className="mt-auto h-full flex space-x-6 items-center justify-center md:justify-start text-sm"
      aria-label="Main Navigation"
    >
      <Link to="/" aria-label="Website logo, go back to homepage.">
        {/* <svg
          aria-hidden="true"
          role="img"
          className="h-12 sm:h-10 w-12 sm:w-10 fill-current hover:text-accent transition duration-150"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 530 373"
        >
          <path d="M307.637 285.072c31.627-38.387 81.471-113.234 86.314-208.168-52.916 10.502-106.705 32.174-156.527 63.866 1.863 9.817 12.854 73.39-3.901 126.722-12.067-46.235-32.049-79.213-42.001-93.702-39.202 31.739-74.552 70.245-103.206 114.852 6.178 26.257 34.812 51.931 71.999 59.845-45.123 3.83-88.857-7.115-127.216-34.21C131.15 116.786 300.265 24.935 486.784-9.521c-36.526 133.853-103.325 234.484-179.147 294.593zM13.373 271.268C-1.682 182.688 58.725 72.512 126.585 30.505c-13.643 22.125-19.636 34.08-28.477 60.978C174.923 21.302 289.322-3.54 386.365-12.123 234.265 29.23 77.778 118.242 13.374 271.269zM487.735 87.666c19.744 82.303 10.79 144.022-19.432 195.124-1.387-26.878-10.634-51.175-10.665-51.257-.007.146-21.579 71.84-82.148 118.436 8.956-20.031 11.304-44.879 11.304-44.879-43.279 21.442-102.288 45.54-166.712 47.429 138.556-52.395 221.66-135.299 267.653-264.853z" />
        </svg> */}
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          viewBox="0 0 481.882 481.882" saria-hidden="true"
          role="img"
          className="h-12 sm:h-10 w-12 sm:w-10 fill-current hover:text-accent transition duration-150">
          <g>
            <g>
              <path d="M474.353,422.852h-56.245v-80.489c0-33.958-27.633-61.591-61.591-61.591c-33.958,0-61.591,27.633-61.591,61.591v80.489
			H7.529c-4.216,0-7.529,3.388-7.529,7.529v43.972c0,4.141,3.313,7.529,7.529,7.529h466.823c4.141,0,7.529-3.388,7.529-7.529
			v-43.972C481.882,426.24,478.494,422.852,474.353,422.852z M356.53,367.879c-14.088,0-25.508-11.421-25.508-25.508
			s11.42-25.508,25.508-25.508c14.088,0,25.508,11.421,25.508,25.508S370.617,367.879,356.53,367.879z"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M106.767,307.878c-4.216,0-7.529,3.388-7.529,7.529v43.972c0,4.141,3.313,7.529,7.529,7.529h173.1v-24.547
			c0-12.411,3.031-24.104,8.289-34.484H106.767z"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M288.228,59.851c2.561,0,5.06,0.241,7.527,0.587V33.732c0-4.141-3.388-7.529-7.529-7.529h-14.08V7.529
			c0-4.141-3.313-7.529-7.529-7.529h-51.426c-4.141,0-7.529,3.388-7.529,7.529v18.673h-14.08c-4.141,0-7.529,3.388-7.529,7.529
			v200.583c0,4.141,3.388,7.529,7.529,7.529h8.583v21.459c0,4.216,3.388,7.529,7.529,7.529h62.419c4.141,0,7.529-3.313,7.529-7.529
			v-21.459h8.583c4.141,0,7.529-3.388,7.529-7.529v-67.089c-2.467,0.346-4.966,0.587-7.527,0.587
			c-29.765,0-53.978-24.217-53.978-53.981S258.464,59.851,288.228,59.851z"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M341.489,105.458c0.428,2.736,0.717,5.519,0.717,8.373c0,19.485-10.409,36.539-25.927,46.032
			c29.833,21.753,47.693,56.088,47.693,93.727c0,4.153-0.305,8.313-0.759,12.463c22.148,1.93,41.574,13.324,54.314,30.098
			c3.512-13.917,5.475-28.142,5.475-42.562C423.002,193.202,391.67,137.424,341.489,105.458z"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M288.228,74.909c-21.463,0-38.92,17.459-38.92,38.923c0,21.463,17.456,38.923,38.92,38.923s38.919-17.459,38.919-38.923
			C327.147,92.369,309.691,74.909,288.228,74.909z M295.757,121.361h-15.059v-15.059h15.059V121.361z"/>
            </g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
        </svg>

      </Link>
      <span className="hidden sm:flex flex-grow items-center space-x-6">
        <NavLink to="/" title="Home" selected={activePage === "/"}>
          Home
        </NavLink>
        <NavLink to="/news" title="News" selected={activePage === "news"}>
          News
        </NavLink>
        <NavLink to="/about" title="About" selected={activePage === "about"}>
          About
        </NavLink>
        {/* <NavExtLink
          to="https://github.com/sadanand-singh"
          title="Github"
          selected={activePage === "github"}
        >
          Github
        </NavExtLink> */}
      </span>
      <ThemeToggle className="hidden sm:block hover:text-accent transition duration-150" />
    </nav>

    <div className="hidden sm:block">
      <div className="-mt-120 sm:-mt-120 ml-4">
        <BlobHeader />
      </div>
    </div>
  </header>
)

export default Header
